import * as React from 'react';
import { navigate } from 'gatsby-link'
import {Button, TextField} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {addMessage} from "../features/alerts/messagesSlice";
import {v1 as uuidv1} from "uuid";

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
        .join(`&`)
}

export const ContactForm = () => {
    const dispatch = useDispatch();
    const [botField, setBotField] = React.useState(``);
    const [name, setName] = React.useState(``);
    const [email, setEmail] = React.useState(``);
    const [message, setMessage] = React.useState(``);

    const validateForm = () => {
        return name.length > 0 && email.length > 0 && message.length > 0;
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target;
        fetch(`/`, {
            method: `POST`,
            headers: { 'Content-Type': `application/x-www-form-urlencoded` },
            body: encode({
                'form-name': form.getAttribute(`name`),
                'botField' : botField,
                'name' : name,
                'email' : email,
                'message' : message,
            }),
        })
            .then(() => navigate(form.getAttribute(`action`)))
            .catch((error) => dispatch(addMessage({id:uuidv1(), text:error, priority: `low`})));
    };

    return (
        <form
            action="/thank-you"
            data-netlify="true"
            encType="application/x-www-form-urlencoded"
            method="post"
            name="contact"
            netlify-honeypot="hBotField"
            onSubmit={handleSubmit}
        >
            <p className="hidden"><input name="hBotField" onChange={e => {setBotField(e.target.value)}} type="text" /></p>
            <input name="form-name" type="hidden" value="contact" />
            <TextField
                autoComplete="name"
                defaultValue={name}
                fullWidth
                id="name"
                label="Name"
                margin="normal"
                name="name"
                onChange={e => {setName(e.target.value)}}
                required
                variant="outlined"
            />
            <TextField
                autoComplete="email"
                defaultValue={email}
                fullWidth
                id="email"
                label="Email address"
                margin="normal"
                name="email"
                onChange={e => {setEmail(e.target.value)}}
                required
                variant="outlined"
            />
            <TextField
                defaultValue={message}
                fullWidth
                id="message"
                label="Message"
                margin="normal"
                maxRows={4}
                multiline
                name="message"
                onChange={e => {setMessage(e.target.value)}}
                required
                variant="outlined"
            />

            <Button className="mr-4 mt-4" variant="outlined">
                Clear
            </Button>
            <Button
                className="mt-4"
                color="primary"
                disabled={!validateForm()}
                type="submit"
                variant="contained"
            >
                Send
            </Button>
        </form>
    );
};
