import * as React from 'react';
import {Paper} from "@material-ui/core";
import {Layout} from "../common/Layout";
import {SEO} from "../components/seo";
import {ContactForm} from "../common/ContactForm";
import {Heading} from "../components/heading";

function ContactPage() {
    return (
        <Layout>
            <SEO keywords={[`ccg`, `collectible`, `card`, `game`, `trade`]} title="Contact"/>
            <Heading className="mb-4" text="Contact" />
            <Paper className="md:w-1/2 p-4">
                <ContactForm />
            </Paper>
        </Layout>
    );
}

export default ContactPage;
